<template>

	<div class="container">
		<div class="row progress-bar-container justify-content-center align-items-center text-light">
			<div class="col-1">
				<b>Steps:</b>
			</div>
			<div class="col-10">
				<div class="progress" style="height: 3px;">
					<div class="progress-bar bg-light" role="progressbar" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
				</div>
			</div>
			<div class="col-1 text-center">
				<b>1 of 2</b>
			</div>
		</div>
		<div class="row header mt-10">
			<div class="col">
				<h3>Creating New Course</h3>
			</div>
		</div>

		<div class="row new-course">
			<div class="col-6">
				<v-form ref="form" class="brand-form">
					<InputTextField
						:id="'new_course_name'"
						v-model="courseName"
						:rules="[rules.required]"
					>
						<template #label>Enter A Course Name</template>
					</InputTextField>
					<InputTextArea
						v-model="description"
						:id="'new_course_description'"
					>
						<template #label> Description</template>
					</InputTextArea>

					<InputImageFile
						v-model="mainImage"
						:valueName="'homePageMainImage'"
						:id="'new_course_main_image'"
						:name="'new_course_main_image'"
					>
						<template #span
							>FILE UPLOAD</template
						>
						<template #button>Click here to add a photo</template>
					</InputImageFile>


					<InputSelect
						:id="'organization_settings_home_subheader'"
						v-model="type"
						:items="courseTypes"
						:itemText="'title'"
						:itemValue="'id'"
						:rules="[rules.required]"
						label="Course Type"
					>
						<template #label>Course Type</template></InputSelect
					>
				</v-form>
			</div>

			<div class="col-12">
				<div class="row justify-content-center align-items-center">
					<div class="col">
						<FormMessage v-if="message">{{ message }}</FormMessage>
					</div>
					<div class="col text-right">
						<v-btn
							class="admin-primary-button primary-contrast-background shadow-none"
							@click="goNext"
							>Next</v-btn
						>
					</div>
				</div>
			</div>

		</div>
	</div>

</template>
<script>
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";
import { mapGetters } from "vuex";
import AdminDefaultDescription from "@/components/AdminDefaultDescription.vue";
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import FormMessage from "@/components/FormMessage.vue";
import InputImageFile from "@/components/InputImageFile.vue";
import InputTextField from "@/components/InputTextField.vue";

export default {
	components: {
		AdminDefaultHeader,
		AdminDefaultDescription,
		AdminDefaultSubheader,
		FormMessage,
		InputImageFile,
		InputTextField
	},
	name: "NewCourseStep1",
	props: [
		"newCourseName",
		"newCourseDescription",
		"newCourseMainImage",
		"newCourseType",
		"message"
	],
	computed: {
		...mapGetters(["isLoading", "courseTypes"]),
		courseName: {
			get() {
				return this.newCourseName;
			},
			set(val) {
				this.$emit("setValue", {
					key: "newCourseName",
					value: val
				});
			}
		},
		description: {
			get() {
				return this.newCourseDescription;
			},
			set(val) {
				this.$emit("setValue", {
					key: "newCourseDescription",
					value: val
				});
			}
		},
		mainImage: {
			get() {
				return this.newCourseMainImage;
			},
			set(val) {
				this.$emit("setValue", {
					key: "newCourseMainImage",
					value: val
				});
			}
		},
		type: {
			get() {
				return this.newCourseType;
			},
			set(val) {
				this.$emit("setValue", {
					key: "newCourseType",
					value: val
				});
			}
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			type: 1
		};
	},
	methods: {
		async validate() {
			return await this.$refs.form.validate();
		},
		async goNext() {
			this.$emit("setMessage", "");
			if ((await this.validate()) === false) {
				this.$emit("setMessage", "Check fields");
				return;
			}
			this.$emit("selectComponent", "NewCourseStep2");
		}
	},
	mounted() {
		console.log(this.courseTypes)
	}
};
</script>

<style></style>
